/* eslint-disable linebreak-style */
/* eslint-disable camelcase */
/* eslint-disable linebreak-style */
/* global gformFormatMoney,Currency,GFCalc,rgars,gform,gformGetDecimalSeparator,gformCleanNumber,gformIsNumber,GFMergeTag,GWPAdvancedNumberField */
import '../css/style.scss';

import Decimal from 'decimal.js-light';

window.GWPAdvancedNumberField = null;

const GWPAdvancedNumberFields = [];

( function() {
	window.GWPAdvancedNumberField = function( fields ) {
		const self = GWPAdvancedNumberField;

		self.bindEvents = function() {
			gform.addFilter( 'gform_calculation_result', function( result, formulaField, formId ) {
				return self.filterCalculationValue( result, formId + '_' + formulaField.field_id );
			} );

			gform.addFilter( 'gform_calculation_format_result', function( formattedResult, result, formulaField, formId ) {
				return self.filterFormatValue( formattedResult, result, formId + '_' + formulaField.field_id, false );
			} );

			gform.addFilter( 'gform_calculation_formula', function( formula, formulaField, formId, calcObj ) {
				return self.replaceMathFunctions( formula, formulaField, formId, calcObj );
			} );

			gform.addFilter( 'gform_merge_tag_value_pre_calculation', function( value, mergeTagArr, isVisible, formulaField, formId ) {
				return self.getCalculationValue( value, mergeTagArr, isVisible, formulaField, formId );
			} );
		};

		/**
		 * Prepare the value for use in calculations.
		 *
		 * @since 1.0
		 *
		 * @param {string}  value
		 * @param {Array}   mergeTagArr
		 * @param {boolean} isVisible
		 * @param {Object}  formulaField
		 * @param {int}     formId
		 * @return
		 */
		self.getCalculationValue = function( value, mergeTagArr, isVisible, formulaField, formId ) {
			const formFieldId = formId + '_' + mergeTagArr[ 1 ];

			if ( formFieldId in GWPAdvancedNumberFields ) {
				// Custom Unit enabled.
				if ( GWPAdvancedNumberFields[ formFieldId ].hasOwnProperty( 'customUnit' ) && GWPAdvancedNumberFields[ formFieldId ].customUnit.enabled ) {
					// Remove custom unit string.
					value = value.toString().replace( GWPAdvancedNumberFields[ formFieldId ].customUnit.unitValue, '' );
				}

				// Slider enabled.
				if ( GWPAdvancedNumberFields[ formFieldId ].hasOwnProperty( 'showAsSlider' ) && GWPAdvancedNumberFields[ formFieldId ].showAsSlider.enabled ) {
					// Rounding + fixedNotation enabled.
					if ( GWPAdvancedNumberFields[ formFieldId ].hasOwnProperty( 'roundValue' ) && GWPAdvancedNumberFields[ formFieldId ].roundValue.enabled && GWPAdvancedNumberFields[ formFieldId ].roundValue.fixedNotation ) {
						// Slider input.val() returns decimal point notation (in FF), but keeps fixed decimals. Here we parse 2.00 to 2.
						value = parseFloat( value );
					}
				}
			}
			return value;
		};

		/**
		 * Replace min / max before calculations.
		 *
		 * @since 1.0
		 *
		 * @param {string} formula      The formule.
		 * @param {Object} formulaField The field with formula.
		 * @param {int}    formId       The form id.
		 * @param {Object} calcObj      The calc Object.
		 *
		 * @return {number|string}
		 */
		self.replaceMathFunctions = function( formula, formulaField, formId, calcObj ) {
			const regEx = /(min|max|pow)\(([^\(]+?)[?^\)]/gi;
			const matches = formula.matchAll( regEx );
			let parsed = '';
			let result = 0;
			for ( const match of matches ) {
				parsed = calcObj.replaceFieldTags( formId, match[ 2 ], formulaField ).replace( / /g, '' ).split( ',' );
				switch ( match[ 1 ].toLowerCase() ) {
					case 'max':
						result = Math.max( ...parsed );
						break;
					case 'min':
						result = Math.min( ...parsed );
						break;
					case 'pow':
					// convert to fixed to avoid scientific notation, which is not accepted by GFCalc.
						try {
							result = new Decimal( Math.pow( parsed[ 0 ], parsed[ 1 ] ) ).toFixed( 21 );
						} catch ( error ) {
							result = Math.pow( parsed[ 0 ], parsed[ 1 ] );
							console.log( result + 'not a valid calculation value.' );
							console.log( error );
						}
				}
				formula = formula.replace( match[ 0 ], result );
			}
			return formula;
		};

		/**
		 * Round the value.
		 *
		 * @since 1.0
		 *
		 * @param {number}  value          The value.
		 * @param {int}     decimalPlaces  The decimal places.
		 * @param {string}  roundingMethod The rounding method.
		 * @param {boolean} fixedNotation  If fixed notation set.
		 *
		 * @return {number|string}
		 */
		self.roundValue = function( value, decimalPlaces, roundingMethod, fixedNotation ) {
			/**
			 * Ceil or Floor can give unexpected results because in JS 0.1 + 0.2 = 0.30000000000000004. Therefore we convert the value to string by using toFixed(7) before applying the rounding settings. Which is 1 more than the supported 6 decimals.
			 * When changing this take gravityforms.js Currency.numberFormat() limitations into account which we use for formatting of the calculated numbers, because 0.0000000001 is being added to the number when rounded (in GF2.6).
			 *
			 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed#description
			 * @see https://en.wikipedia.org/wiki/Floating-point_arithmetic#Accuracy_problems
			 */
			const v = new Decimal( value.toFixed( 7 ) );
			let rm; // rounding method.
			switch ( roundingMethod ) {
				case 'round':
					rm = Decimal.ROUND_HALF_UP;
					break;
				case 'ceil':
					rm = Decimal.ROUND_UP;
					break;
				case 'floor':
					rm = Decimal.ROUND_DOWN;
					break;
			}

			if ( fixedNotation ) {
				return v.toFixed( decimalPlaces, rm ).toString();
			}

			return parseFloat( v.toDecimalPlaces( decimalPlaces, rm ).toString() );
		};

		/**
		 * Apply the custom unit.
		 *
		 * @since 1.0
		 *
		 * @param {string|number} value     The value.
		 * @param {string}        unit      The custom unit.
		 * @param {string}        placement The unit placement.
		 *
		 * @return {string}
		 */
		self.applyCustomUnit = function( value, unit, placement ) {
			if ( placement === 'after' ) {
				return value + unit;
			}

			return unit + value;
		};

		/**
		 * Display the slider value.
		 *
		 * @since 1.0
		 * @param {Event|string} e               The event or field ID.
		 * @param {string}       formattedNumber
		 *
		 */
		self.displaySliderValue = function( e, formattedNumber ) {
			let element, fieldId;

			if ( e.target ) {
				fieldId = e.target.id.replace( 'input_', '' );
				element = e.target;
			} else {
				fieldId = e;
				element = document.getElementById( 'input_' + fieldId );
			}

			const props = GWPAdvancedNumberFields[ fieldId ];

			if ( props.hasOwnProperty( 'showAsSlider' ) && props.showAsSlider.enabled ) {
				const rawValue = element.value;
				element.nextElementSibling.value = gform.applyFilters( 'gravityWP.advancedNumberField.sliderOutput', formattedNumber, rawValue, fieldId, GWPAdvancedNumberFields[ fieldId ] );
			}
		};

		/**
		 * Filter the field value based on the advanced number settings for calculations results.
		 *
		 * @since 1.0
		 *
		 * @param {number} value   The value.
		 * @param {string} fieldId The field Id in the format of "21_2" (formId_fieldId).'
		 *
		 * @return {number}
		 */
		self.filterCalculationValue = function( value, fieldId ) {
			if ( ! GWPAdvancedNumberFields.hasOwnProperty( fieldId ) ) {
				return value;
			}

			const props = GWPAdvancedNumberFields[ fieldId ];
			let decimalPlaces = -1;

			// Get absolute value if set.
			if ( props.hasOwnProperty( 'absoluteValue' ) && props.absoluteValue.enabled ) {
				value = Math.abs( parseFloat( value ) );
			}

			// Rounding values if set.
			if ( props.hasOwnProperty( 'roundValue' ) && props.roundValue.enabled ) {
				decimalPlaces = parseInt( props.roundValue.decimalPlaces );
				value = self.roundValue( parseFloat( value ), decimalPlaces, props.roundValue.roundingMethod, props.roundValue.fixedNotation );
			}

			return value;
		};

		/**
		 * Filter the field value based on the advanced number settings. Filters input change events, initilization values (formatted values) and calculation results (raw value).
		 *
		 * @since 1.0
		 *
		 * @param {string|boolean} formattedResult Has a formatted result or false.
		 * @param {number}         value           Raw number to be formatted.
		 * @param {string}         fieldId         The field Id in the format of "21_2" (formId_fieldId).'
		 * @param {boolean}        isDOMValue      DOM value or raw value.
		 *
		 * @return {string} The formatted value.
		 */
		self.filterFormatValue = function( formattedResult, value, fieldId, isDOMValue ) {
			if ( ! GWPAdvancedNumberFields.hasOwnProperty( fieldId ) ) {
				return formattedResult;
			}
			const props = GWPAdvancedNumberFields[ fieldId ];
			const decimalSeparator = gformGetDecimalSeparator( props.numberFormat );
			let thousandSeparator = decimalSeparator === '.' ? ',' : '.';
			thousandSeparator = props.showThousandsSeparator ? thousandSeparator : '';
			let decimalPlaces = -1;

			// Remove the custom unit from the value.
			const customUnitEnabled = props.hasOwnProperty( 'customUnit' ) && props.customUnit.enabled;
			if ( customUnitEnabled ) {
				value = value.toString().replace( props.customUnit.unitValue, '' );
			}

			const originalValue = value;
			if ( isDOMValue ) {
				// transform to a decimal dot number.
				value = gformCleanNumber( value, '', '', decimalSeparator );

				// If the converted value is not a number, return early.
				if ( ! gformIsNumber( value ) ) {
					return originalValue;
				}
			}

			// Get absolute value if set.
			if ( props.hasOwnProperty( 'absoluteValue' ) && props.absoluteValue.enabled ) {
				value = Math.abs( value );
			}

			// Rounding values if set.
			if ( props.hasOwnProperty( 'roundValue' ) && props.roundValue.enabled ) {
				decimalPlaces = parseInt( props.roundValue.decimalPlaces );
				value = self.roundValue( parseFloat( value ), decimalPlaces, props.roundValue.roundingMethod, props.roundValue.fixedNotation );
			}

			// Format the number.
			const currency = new Currency();
			const formattedNumber = props.numberFormat === 'currency' ? gformFormatMoney( value, true ) : currency.numberFormat( value, decimalPlaces, decimalSeparator, thousandSeparator, props.roundValue.fixedNotation );
			if ( ! props.hasOwnProperty( 'showAsSlider' ) || ! props.showAsSlider.enabled ) {
				value = formattedNumber;
			} else {
				document.getElementById( 'input_' + fieldId ).setAttribute( 'data-slidervalue', formattedNumber );
				self.displaySliderValue( fieldId, formattedNumber );
			}

			// Apply custom unit if set.
			if ( customUnitEnabled ) {
				const customUnitValue = self.applyCustomUnit( formattedNumber, props.customUnit.unitValue, props.customUnit.unitPlacement );
				if ( ! props.hasOwnProperty( 'showAsSlider' ) || ! props.showAsSlider.enabled ) {
					value = customUnitValue;
				} else {
					document.getElementById( 'input_' + fieldId ).setAttribute( 'data-slidervalue', customUnitValue );
					self.displaySliderValue( fieldId, customUnitValue );
				}
			}

			if ( props.hasOwnProperty( 'showAsSlider' ) && props.showAsSlider.enabled ) {
				return originalValue;
			}

			return value;
		};

		/**
		 * Update the field value based on the advanced number settings.
		 *
		 * @since 1.0
		 *
		 * @param {Event} e The Event object.
		 */
		self.updateValue = function( e ) {
			e.target.value = self.filterFormatValue( false, e.target.value, e.target.id.replace( 'input_', '' ), true );
		};

		/**
		 * Trigger the change event for a Slider input.
		 *
		 * @since 1.0
		 *
		 * @param {Event} e The Event object.
		 */
		self.triggerSliderChange = function( e ) {
			const event = new Event( 'change' );
			e.target.dispatchEvent( event );
		};

		/**
		 * Remove custom unit from the input value.
		 *
		 * @since 1.0
		 *
		 * @param {string} customUnit The custom unit string.
		 * @param {Event}  e          The Event object.
		 */
		self.removeCustomUnit = function( customUnit, e ) {
			e.target.value = e.target.value.replace( customUnit, '' );
		};

		/**
		 * Returns the ANF field settings so the settings are accessible for frontend user scripts.
		 *
		 * @since 1.0
		 */
		self.getGWPAdvancedNumberFieldsSettings = function() {
			return GWPAdvancedNumberFields;
		};

		/**
		 * Init the min/max range calculation.
		 *
		 * @since 1.0
		 *
		 * @param {string} fieldId The range input ID.
		 * @param {string} type    min or max.
		 */
		self.initRangeCalculation = function( fieldId, type ) {
			if ( type !== 'min' && type !== 'max' ) {
				return;
			}

			const props = GWPAdvancedNumberFields[ fieldId ];

			if ( rgars( props, type + 'ValueCalculation/enabled', false ) === true ) {
				let formula = rgars( props, type + 'ValueCalculation/formula', '' );
				const formFieldId = fieldId.split( '_' );
				if ( formula.length > 0 ) {
					const matches = GFMergeTag.parseMergeTags( formula );
					if ( matches.length > 0 ) {
						for ( const i in matches ) {
							if ( ! matches.hasOwnProperty( i ) ) {
								continue;
							}
							const inputIdToBind = formFieldId[ 0 ] + '_' + matches[ i ][ 1 ];
							const input = document.getElementById( 'input_' + inputIdToBind );
							input.addEventListener( 'change', ( e ) => self.applyRangeCalculation( formFieldId[ 0 ], formFieldId[ 1 ], formula, type, e ) );
						}
					}
				} else {
					// No formula, initialize range on 0.
					formula = '0';
				}

				self.applyRangeCalculation( formFieldId[ 0 ], formFieldId[ 1 ], formula, type );
			}
		};

		/**
		 * Applies calculated min/max range to slider input.
		 *
		 * @param {string} formId  Form id (numeric string).
		 * @param {string} fieldId Field id (numeric string).
		 * @param {string} formula The formula containing merge tags.
		 * @param {string} type    Type of range boundary: min or max.
		 */
		self.applyRangeCalculation = function( formId, fieldId, formula, type ) {
			const formulaField = new Object;
			formulaField.field_id = fieldId;
			formulaField.formula = formula;
			const result = self.parseEvaluateFormula( formulaField, formId );
			const range = document.getElementById( 'input_' + formId + '_' + fieldId );
			const previousVal = range.value;
			range.setAttribute( type, result );

			if ( ( type === 'min' && previousVal < result ) || ( type === 'max' && previousVal > result ) ) {
				range.dispatchEvent( new Event( 'input' ) );
				const props = GWPAdvancedNumberFields[ formId + '_' + fieldId ];
				if ( rgars( props, 'showAsSlider/enabled', false ) === true && rgars( props, 'showAsSlider/continuousUpdate', false ) === false ) {
					range.dispatchEvent( new Event( 'change' ) );
				}
			}
		};

		/**
		 * Parse and evaluate the formula.
		 *
		 * @param {Object} formulaField formula Field.
		 * @param {string} formId       Form id (numeric string).
		 * @return
		 */
		self.parseEvaluateFormula = function( formulaField, formId ) {
			// Retrieve or create the GFCalc object.
			let calcObj = rgars( window, 'gf_global/gfcalc/' + formId, null );
			if ( typeof ( calcObj ) !== GFCalc ) {
				calcObj = new GFCalc( formId, {} );
			}

			// Initialize other
			const formula = gform.applyFilters( 'gform_calculation_formula', formulaField.formula, formulaField, formId, calcObj ),
				expr = calcObj.replaceFieldTags( formId, formula, formulaField ).replace( /(\r\n|\n|\r)/gm, '' );
			let result = '';

			if ( calcObj.exprPatt.test( expr ) ) {
				try {
					// Run sanitized calculation.
					result = eval( expr );
					return result;
				} catch ( e ) { }
			} else {

			}
		};

		self.init = function( ) {
			// Copy all ANF field settings into a global variable to support multiple forms.
			for ( const prop in fields ) {
				if ( fields.hasOwnProperty( prop ) ) {
					// Push each value from `obj` into `extended`
					GWPAdvancedNumberFields[ prop ] = fields[ prop ];
				}
			}

			// Add event listeners.
			for ( const fieldId in fields ) {
				const input = document.getElementById( 'input_' + fieldId );
				const props = GWPAdvancedNumberFields[ fieldId ];

				if ( input.type === 'range' && input.defaultValue !== '' ) {
					// GF seems to assume decimal point notation for default field values, so we filter it as if it is a raw value.
					input.value = self.filterFormatValue( false, input.defaultValue, fieldId, false );
				} else if ( input.value !== '' ) {
					// input.value is a value from the DOM, which should be formatted.
					input.value = self.filterFormatValue( false, input.value, fieldId, true );
				}

				if ( input.type === 'range' ) {
					// Format the textual output for the slider.
					input.addEventListener( 'input', self.updateValue );

					self.initRangeCalculation( fieldId, 'min' );
					self.initRangeCalculation( fieldId, 'max' );

					if ( rgars( props, 'showAsSlider/enabled', false ) === true && rgars( props, 'showAsSlider/continuousUpdate', false ) === true ) {
						// Trigger a change event continuously when the slider slides.
						input.addEventListener( 'input', self.triggerSliderChange );
					}
				} else {
					input.addEventListener( 'change', self.updateValue );
				}

				// Add listeners for removing custom unit when field has focus.
				if ( props.hasOwnProperty( 'customUnit' ) && props.customUnit.enabled && input.type !== 'range' && ! input.readOnly ) {
					input.addEventListener( 'focus', ( e ) => self.removeCustomUnit( props.customUnit.unitValue, e ) );
					input.addEventListener( 'focusout', self.updateValue );
				}
			}

			// Bind Events.
			self.bindEvents();
		};

		self.init( fields );
	};
}() );
